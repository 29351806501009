import {React,useEffect,useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Paper } from '@mui/material';
import { getUser } from './lib/api-payment';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
const Info = () => {
    const [rows,setRows]=useState([])
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await getUser();
            const jsonData = response
     
            const reversedRows = jsonData.data.reverse();
            const rowsWithUniqueIds = reversedRows.map((row, index) => ({
              ...row,
              srNo: index + 1, 
            }));
            console.log(rowsWithUniqueIds)
            setRows(rowsWithUniqueIds);
          
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
      }, []);
      
    
    
    const columns = [
        { field: 'srNo', headerName: 'SR No',width: 60 , headerClassName: 'Header'  },
        { field: 'country', headerName: 'Country', headerClassName: 'Header' },
        { field: 'memberType', headerName: 'Events', headerClassName: 'Header' },
        { field: 'course', headerName: 'Course', headerClassName: 'Header' },
        { field: 'NAME', headerName: 'Name', headerClassName: 'Header'},
        { field: 'email', headerName: 'Email' , headerClassName: 'Header'},
        { field: 'organization', headerName: 'Organization' , headerClassName: 'Header'},
        { field: 'designation', headerName: 'Designation' , headerClassName: 'Header'},
        { field: 'address', headerName: 'Address', headerClassName: 'Header' },
        { field: 'postalCode', headerName: 'Postal Code' , headerClassName: 'Header'},
        { field: 'dob', headerName: 'Date of Birth' , headerClassName: 'Header'},
        { field: 'passportNo', headerName: 'Passport no' , headerClassName: 'Header'},
        { field: 'dateOfIssue', headerName: 'Date of Issue' , headerClassName: 'Header'},
        { field: 'dateOfExpiry', headerName: 'Date of Expiry' , headerClassName: 'Header'},
        { field: 'placeOfIssue', headerName: 'Place of Issue' , headerClassName: 'Header'},
        { field: 'dateOfArrival', headerName: 'Date of Arrival' , headerClassName: 'Header'},
        { field: 'dateOfDeparture', headerName: 'Date of Departure' , headerClassName: 'Header'},
        {
            field: 'amount',
            headerName: 'Amount',
            headerClassName: 'Header',
            width: 150,
            renderCell: (params) => {
              const isIndia = params.row.country === 'India';
              const currencySymbol = isIndia ? '₹' : '$';
              return (
                <span>
                  {currencySymbol} {params.value}
                </span>
              );
            },
          },
    ];
const logout=()=>{
    navigate('/')
}
    return (
        <>
        <Button variant="contained"  color="primary" startIcon={<LogoutIcon />}sx={{marginLeft:'90%',marginTop:'10px'}} onClick={logout}>Logout</Button>
           
            <DataGrid
            sx={{marginLeft:'10px',marginRight:'10px',marginTop:'5px'}}
                rows={rows}
                columns={columns}
        
                rowsPerPageOptions={[5, 10, 20]}
                pagination
            />
       
        </>
    );
};

export default Info;
