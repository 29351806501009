// export const APP_BASE_PATH = "http://localhost:3002/";
// export const PAYMENT_BASE_PATH = "http://localhost:3002/api/paypal"; 
// export const RAZOR_BASE_PATH = "http://localhost:3002/api/razor";
// export const ADD_BASE_PATH = "http://localhost:4000/api/user";
// export const LOGIN_BASE_PATH = "http://localhost:4000/api/login"; 

// export const APP_BASE_PATH = "https://api.arms2024.org/";
// export const PAYMENT_BASE_PATH = "https://api.arms2024.org/api/paypal"; 
// export const RAZOR_BASE_PATH = "https://api.arms2024.org/api/razor";
// export const ADD_BASE_PATH = "https://api.arms2024.org/api/user"; 
// export const ADD_BASE_PATH = "https://api.arms2024.org/api/login"; 


export const APP_BASE_PATH = "https://api.icold2024.org/";
export const PAYMENT_BASE_PATH = "https://api.icold2024.org/api/paypal"; 
export const RAZOR_BASE_PATH = "https://api.icold2024.org/api/razor";
export const ADD_BASE_PATH = "https://api.icold2024.org/api/user"; 
export const LOGIN_BASE_PATH = "https://api.icold2024.org/api/login"; 