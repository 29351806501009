import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useNavigate } from 'react-router-dom';

import { Userlogin } from './lib/api-payment';

const LoginPage = () => {
    const [Username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    // const error = useSelector(state => state.auth.error);
    const navigate=useNavigate()

    const handleLogin = async (e) => {
        e.preventDefault();
        const loginData = { Username, password };
        try {
            const response = await Userlogin(loginData);
            console.log('User logged in successfully:', response.data);
            if (response.data.message === 'User logged in successfully') { // Corrected syntax error
                navigate('/info');
            } else {
                window.alert(response.data.message);
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '5px', width: '300px' }} onSubmit={handleLogin}>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h2>Login</h2>
                </div>
                <div>
                    <TextField
                        id="Username"
                        label="Username"
                        type="text"
                        variant="outlined"
                        fullWidth
                        required
                        value={Username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div style={{ marginTop: '10px' }}>
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px', width: '100%' }}>Login</Button>
            </form>
        </div>
    );
};

export default LoginPage;
