
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import Info from './Info';

function App() {
  
  return (
    <>
    
    <Router>
      
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path='/info' element={<Info/>}/>
        
      </Routes>

  </Router>
  
    </>
  );
}

export default App;
